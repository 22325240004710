<template>
  <div class=" pb-6 home-page min-h-screen">
    <div class="px-4 py-16 mx-auto sm:max-w-xl flex flex-col md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div class="flex flex-col items-center justify-center xl:flex-row">

        <!--          Send OTP Form-->
        <div
          v-if="otpSent === false"
          class="bg-white rounded shadow-2xl p-7 sm:p-10">
          <h3 class="mb-4 text-2xl font-semibold sm:text-center sm:mb-6 sm:text-2xl">
            {{ $t('SetPassword') }}
          </h3>
          <form class="text-left w-full"
                @submit="sendOtp">
            <div class="w-full flex">
              <div class="w-full pr-4">
                <div class="mb-1 sm:mb-2">
                  <label for="lastName" class="inline-block mb-1 font-medium">{{ $t('Username') }}<span class="text-danger">*</span></label>
                  <input
                    :placeholder="$t('Username')"
                    required=""
                    type="text"
                    v-model="username"
                    class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                    id="lastName"
                    name="lastName"
                  />
                </div>
                <div class="mb-1 sm:mb-2">
                  <label for="mobile" class="inline-block mb-1 font-medium">{{ $t('MobileNumber') }} <span class="text-danger">*</span></label>
                  <input
                    placeholder="+491234567890"
                    required=""
                    type="text"
                    v-model="mobile"
                    class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                    id="mobile"
                    name="mobile"
                  />
                </div>
              </div>
            </div>


            <div class="mt-4 mb-2 w-full sm:mb-4 mx-auto">
              <button
                type="submit"
                :disabled="!validForm"
                class="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md focus:shadow-outline focus:outline-none"
                :class="!validForm ? 'bg-gray-600' : 'bg-gray-900  hover:bg-gray-800'"
              >
                {{ $t('Verify') }}
              </button>
            </div>
            <p v-if="errorMessage" class="text-xs text-red-500 sm:text-sm">
              {{ errorMessage }}
            </p>
          </form>
        </div>
         <!--          Verify OTP Form-->
        <div
          v-if="otpSent === true && otpValidated === false"
          class="bg-white rounded shadow-2xl p-7 sm:p-10">
          <h3 class="mb-4 text-2xl font-semibold sm:text-center sm:mb-6 sm:text-2xl">
            {{ $t('VerifyOTP') }}
          </h3>
          <form class="text-left w-full"
                @submit="verifyOTP">
            <div class="w-full flex">
              <div class="w-full pr-4">
                <div class="mb-1 sm:mb-2">
                  <label for="lastName" class="inline-block mb-1 font-medium">{{ $t('OTP') }}<span class="text-danger">*</span></label>
                  <input
                    :placeholder="$t('OTP')"
                    required=""
                    type="number"
                    v-model="otp"
                    class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                    id="otp"
                    name="otp"
                  />
                </div>
              </div>
            </div>


            <div class="mt-4 mb-2 w-full sm:mb-4 mx-auto">
              <button
                type="submit"
                :disabled="!validOTPForm"
                class="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md focus:shadow-outline focus:outline-none"
                :class="!validOTPForm ? 'bg-gray-600' : 'bg-gray-900  hover:bg-gray-800'"
              >
                {{ $t('Verify') }}
              </button>
            </div>
            <p v-if="errorMessage" class="text-xs text-red-500 sm:text-sm">
              {{ errorMessage }}
            </p>
          </form>
        </div>

         <!--          Set Password Form-->
        <div
          v-if="otpValidated === true"
          class="bg-white rounded shadow-2xl p-7 sm:p-10">
          <h3 class="mb-4 text-2xl font-semibold sm:text-center sm:mb-6 sm:text-2xl">
            {{ $t('SetPassword') }}
          </h3>
          <form class="text-left w-full"
                @submit="setPassword">
            <div class="w-full flex">
              <div class="w-full pr-4">
                <div class="mb-1 sm:mb-2">
                  <label for="lastName" class="inline-block mb-1 font-medium">{{ $t('Password') }}<span class="text-danger">*</span></label>
                  <input
                    :placeholder="$t('Password')"
                    required=""
                    type="password"
                    v-model="password"
                    @input="changePassCheck"
                    class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                    id="password"
                    name="password"
                  />
                </div>
                <div class="flex flex-col">
                  <p class="text-sm pass-check-item" :class="[passStrengthValue.caps ? 'text-success' : 'text-gray']">{{ $t('UppercaseLetter') }}</p>
                  <p class="text-sm pass-check-item" :class="[passStrengthValue.small ? 'text-success' : 'text-gray']">{{ $t('LowercaseLetter') }}</p>
                  <p class="text-sm pass-check-item" :class="[passStrengthValue.numbers ? 'text-success' : 'text-gray']">{{ $t('Number') }}</p>
                  <p class="text-sm pass-check-item" :class="[passStrengthValue.special ? 'text-success' : 'text-gray']">{{ $t('OneSymbol') }}</p>
                  <p class="text-sm pass-check-item" :class="[passStrengthValue.length ? 'text-success' : 'text-gray']">{{ $t('EightCharacters') }}</p>
                </div>
                <div class="mb-1 sm:mb-2">
                  <label for="lastName" class="inline-block mb-1 font-medium">{{ $t('ConfirmPassword') }}<span class="text-danger">*</span></label>
                  <input
                    :placeholder="$t('ConfirmPassword')"
                    required=""
                    type="password"
                    v-model="cPassword"
                    class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                    id="cpassword"
                    name="cpassword"
                  />
                </div>
              </div>
            </div>


            <div class="mt-4 mb-2 w-full sm:mb-4 mx-auto">
              <button
                type="submit"
                :disabled="!validPasswordForm"
                class="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md focus:shadow-outline focus:outline-none"
                :class="!validPasswordForm ? 'bg-gray-600' : 'bg-gray-900  hover:bg-gray-800'"
              >
                {{ $t('Update') }}
              </button>
            </div>
            <p v-if="errorMessage" class="text-xs text-red-500 sm:text-sm">
              {{ errorMessage }}
            </p>
          </form>
        </div>

      </div>

      <div class="flex justify-center my-6">
        <router-link to="/" class="bg-primary rounded-lg px-4 py-2 text-white">
          <p>Home</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "RegisterSetPassword",
  data() {
    return {
      username: '',
      mobile: '',
      otp: '',
      password: '',
      cPassword: '',
      otpSent: false,
      otpValidated: false,
      otpVerifyResponse: {},
      errorMessage: '',
      passStrengthValue: {
        'caps': false,
        'length': false,
        'special': false,
        'numbers': false,
        'small': false,
      },
    }
  },
  computed: {
    validForm() {
      return this.username !== '' && this.mobile !== '' && this.validPhone(this.mobile)
    },
    validOTPForm() {
      return this.otp !== '' &&  !isNaN(this.otp)
    },
    validPasswordForm() {
      return this.password !== ''
    }
  },
  props: {
    id: {
      default: '',
      type: String,
    },
  },
  methods: {
    changePassCheck(value) {
      let caps = false
      let numbers = false
      let small = false
      let special = false
      for (let index = 0; index < this.password.length; index++) {
        const char = this.password.charCodeAt(index)
        if (char >= 65 && char <= 90) {
          caps = true
        } else if (char >= 48 && char <= 57) {
          numbers = true
        } else if (char >= 97 && char <= 122) {
          small = true
        } else if (char >= 48 && char <= 57) {
          numbers = true
        } else if ([33, 35, 36, 37, 38, 64, 42, 63, "33", "35", "36", "37", "38", "64", "42", "63"].includes(char)) {
          special = true
        }
      }
      this.passStrengthValue.caps = caps
      this.passStrengthValue.numbers = numbers
      this.passStrengthValue.small = small
      this.passStrengthValue.special = special
      if (this.password.length < 8) {
        this.passStrengthValue.length = false
      } else {
        this.passStrengthValue.length = true
      }
      const obj = {...this.passStrengthValue}
      this.passStrengthValue = {}
      this.passStrengthValue = {...obj}
    },
    validPhone(phone) {
      // const re = /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;
      const re = /^\+(?:[0-9]){6,14}[0-9]$/gm;
      return re.test(phone);
    },
    sendOtp(e) {
      this.errorMessage = ''
      e.preventDefault()
      const payload = {
        'registration_uuid': this.id,
        username: this.username,
        mobile: this.mobile,
      }
      this.$vs.loading()
      this.$store.dispatch('customerRegistration/sendRegisterOTP', {payload})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.$vs.notify({
            position: 'top-center',
            title: 'Success',
            text: data.message,
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          })
          this.otpSent = true;
        })
        .catch((err) => {
          console.log(err)
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = err.response.data.message
          } catch (e) {
            msg = err.message
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    verifyOTP(e) {
      this.errorMessage = ''
      e.preventDefault()
      const payload = {
        'registration_uuid': this.id,
        otp: this.otp,
      }
      this.$vs.loading()
      this.$store.dispatch('customerRegistration/verifyRegisterOTP', {payload})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.otpVerifyResponse = data
          this.$vs.notify({
            position: 'top-center',
            title: 'Success',
            text: data.message,
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          })
          this.otpValidated = true;
        })
        .catch((err) => {
          console.log(err)
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = err.response.data.message
          } catch (e) {
            msg = err.message
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    setPassword(e) {
      this.errorMessage = ''
      e.preventDefault()
      if (!(this.passStrengthValue.caps && this.passStrengthValue.length
        && this.passStrengthValue.special && this.passStrengthValue.numbers
        && this.passStrengthValue.small)) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Password is not valid',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      if(this.cPassword !== this.password) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Password not matched',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      }
      const payload = {
        'registration_uuid': this.id,
        'password_key': this.otpVerifyResponse.password_key,
        password: this.password,
      }
      this.$vs.loading()
      this.$store.dispatch('customerRegistration/setRegisterPassword', {payload})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.otpVerifyResponse = data
          this.$vs.notify({
            position: 'top-center',
            title: 'Success',
            text: data.message,
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          })
          if (localStorage.getItem('accessToken')) {
            localStorage.removeItem('accessToken')
            localStorage.clear()
            localStorage.clear()
            this.$router.push('/').catch(() => {
            })
          }

        })
        .catch((err) => {
          console.log(err)
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = err.response.data.message
          } catch (e) {
            msg = err.message
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  mounted() {
  },
}
</script>

<style scoped lang="scss">
.router-link-active {
  color: white !important;
}
</style>
